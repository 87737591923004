@import '../../scss/variables';
@import '../../scss/functions';
@import '../../scss/mixins/direction';

.background-image{
  background: url("../../images/SignUp_SignIn_bg.jpg") no-repeat 50%;
  background-size: cover;
}

.card-wrapper {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  padding: 50px 0;

  .card {
    max-width: 540px;
    width: 100%;
  }
}

.country-container__list {
  margin-bottom: 25px;
}

.active_state {
  display: flex;
  gap: 10px;
}

.select_label_title {
  position: absolute;
  top: -10px;
  left: 15px;
  font-size: 13px;
  color: #1e72bd;
  padding: 0 5px;
  background-color: white;
  width: max-content !important;
  z-index: 1;
}


.select-container {
  position: relative;
}

.checkout-select {
  width: 100%;
  max-width: 100%;
}

.checkout-input {
  padding: 0 15px;
  font-size: 14px;

  &:focus {
    opacity: 1;
  }
}

.custom-select-mark {
  padding-left: 15px;
  -webkit-appearance: none;
}

.select-dots {
  padding: 0 25px 0 15px !important;
  text-overflow: ellipsis;
}

.form-group {
  margin-bottom: 1.5rem;
  min-height: 42px;
  padding-left: unset;

  &.checkout-style {
    margin-bottom: 0 !important;
  }

  select,
  input {
    border: 1px solid #aaa !important;
    border-radius: 5px;
    height: 42px;
    font-size: 16px;
    color: $light-opposite-color;
    padding: 10px;

    &[type="date"]:hover {
      cursor: pointer;
    }
  }

  select {
    margin-bottom: 3px;
  }
}

.block {
  margin: 47px 0;
}

.card {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
}


.empty_orders_list {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-body {
  padding: 3rem;

  .card-title:last-child {
    margin-bottom: 0;
  }

  form{
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}

.card-title {
  text-align: center;
  margin-bottom: 1.75rem;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: .035em;


  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }
}

.signUp-position-relative {
  > div {
    width: 100%;
  }

  .custom_input_checkout {
    .custom_input_field {
      border: 1px solid #b7b7b7
    }
  }
;
}

.login-button {
  max-width: 100%;
  width: 100%;
  height: 40px;
  font-size: 20px;
  padding: 0;
}

.rounded-pills {
  color: white;
  width: 100%;
  border-radius: 5px !important;
  text-align: center;
}

.sbm_button {
  max-width: 100%;
  width: 47%;
  height: 40px;
  font-size: 20px;
  padding: 0;
  align-self: center;
}

.login-page-sing-in {
  gap: 5px;
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: .035em;
  color: #707070;
  align-self: center;

  u {
    cursor: pointer;
  }

  u:hover {
    color: #000000;
  }
}

.account-nav__item {
  .historyIcon-svg {
    stroke: none;
  }
}

.account-nav__item {
  a {
    color: $body-font-alt-color;
    font-size: 16px;
    padding: 5px 26px;
    display: flex;
    align-items: center;
    gap: 5px;

    span {
      display: flex;
      width: 30px;
    }
  }

  .logout {
    color: red;

    path {
      stroke: red;
    }
  }
}

.account-nav__item {
  fill: transparent;
  stroke: none;
}

.account-nav__item--active {
  background-color: #f7f7f7
}

.account-nav__item--active > * {
  stroke: $main-theme-color-light;
}

.account-nav ul .account-nav__item {
  cursor: pointer;
  padding: 8px 0;

  &:hover {
    background-color: #f7f7f7;
  }
}

.account-nav__title {
  font-size: 32px;
  color: #000000;
  margin-bottom: 4rem;
  text-align: center;
}

.account-layout-wrapper {
  display: flex;
  justify-content: center;
  gap: 24px;

  @media(max-width: 991px) and (min-width: 768px) {
    display: grid;
    grid-template-columns: 4fr 6fr;
  }

  @media(max-width:768px) {
    flex-direction: column;
  }
}

.account-layout {
  width: calc(25% - 20px);
}

.account-layout-desktop {
  width: calc(75% - 20px);
}

.account-nav {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    border: 1px solid #e6e6e6;

    li:first-child {
      border-radius: 5px 5px 0 0;
    }
  }
}

.account-nav-username {
  width: 100%;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f4f4;
  border-bottom: 1px solid #ddd;
  font-weight: 600;
  font-size: 16px;
  color: #000;
}

.account-links-body {
  width: max-content;
}

.login-ss {
  .account-menu__form-forgot-link {
    a {
      margin-left: 0 !important;
    }
  }
}

.account-menu__form-forgot-link {
  $local-offset: 5px;
  border-radius: 2px;
  font-size: 15px;
  font-weight: $font-weight-medium;
  background: $btn-light-bg-color;
  color: $btn-light-font-color;
  padding: 0 7px;
  transition: background 0.1s, color 0.1s;
  text-decoration: underline;
  display: block;
  max-width: max-content;
  width: 100%;
  margin: 15px auto;

  @include direction {
    #{$inset-inline-end}: $local-offset;
  }

  &:hover {
    background: none;
    color: #000C66;
    text-decoration: underline;
  }

  &:active {
    background: none;
    color: #3d464d;
  }
}

@mixin local-layout($columns, $gutter) {
  .addresses-list__divider:nth-child(2n) {
    width: $gutter;
  }
  .addresses-list__divider:nth-child(#{$columns * 2}n) {
    width: 100%;
    height: $gutter;
  }
}

.addresses-list__item {
  border: 1px solid #e6e6e6;
  border-radius: 2px;
}

.addresses-list__item--new {
  border-radius: $card-border-radius;
  border: $card-border-width dashed #ebebeb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  height: 124px;

  &:hover {
    color: #808080;
  }
}

.addresses-list__item--new:hover .addresses-list__plus {
  border-color: #e0e0e0;

  &::before,
  &::after {
    background-color: #dbdbdb;
  }
}

.address-card {
  position: relative;
  width: calc((100% - 32px) / 3);

  @media screen and (max-width: 600px) {
    width: calc((100% - 16px) / 2);
  }

  @media screen and (max-width: 425px) {
    width: 100%;
  }
}

.address-card__body {
  padding: 1rem;
  font-size: 15px;
  line-height: 18px;
}

.address-card--featured {
  .address-card__body {
    padding: 2rem;
  }

  .address-card__name {
    font-weight: $font-weight-bold;
  }


  @media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
    .address-card__body {
      padding: 1.5rem;
    }
  }
  @media (max-width: breakpoint(sm-end)) {
    .address-card__body {
      padding: 1.375rem;
    }
  }
}

.address-card__name {
  font-size: 16px;
  font-weight: $font-weight-bold;
}

.address-card__row + .address-card__row {
  margin-top: .75rem;
}

.address-card__row-title {
  font-size: 12px;
  color: $card-font-muted-color;
}

.addresses-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .new_address_link {
    border-radius: 2px;
    border: 2px dashed #ebebeb;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc((100% - 32px) / 3);
    padding: 2rem;

    @media screen and (max-width: 600px) {
      width: calc((100% - 16px) / 2);
    }

    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }
}

.address-card__footer {
  margin-top: 6px;
  display: flex;
  justify-content: flex-end;
}

.address-remove-btn {
  cursor: pointer;
  margin-left: 13px;

  &:hover {
    color: $main-theme-color-light;

    .mobile-footer-dropdowns-list {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}

.addresses-list__divider:last-child {
  display: none;
}

.address-page-body {
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
}

.address-page-title {
  padding-bottom: 33px;
  border-bottom: 1px solid $card-border-color;
  margin-bottom: 33px;
}

.select-container {
  position: relative;
}

.form-group {
  margin-bottom: 1.5rem;
  min-height: 42px;
  padding-left: unset;

  &.checkout-style {
    margin-bottom: 0 !important;
  }

  select,
  input {
    border: 1px solid #aaa;
    border-radius: 5px;
    height: 42px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    color: #3d464d;
    padding: 10px;

    &[type="date"]:hover {
      cursor: pointer;
    }
  }

  select {
    margin-bottom: 3px;
  }
}

.account-menu__form {
  padding: 0 30px 32px;

  .form-group {
    margin-bottom: 1rem;
  }
}

.new-adresses {

  div {
    width: 100%;
    display: flex;
    gap: 0 15px;
  }

  .form-row {
    div {
      input {
        width: 100%;
      }

    }
  }

  .form-group {
    flex-wrap: wrap;
  }

}

.edit-adresses-wrapper {
  div {
    width: 100%;
    display: flex;
    gap: 0 15px;

  }

  .form-row {
    div {
      input {
        width: 100%;
      }

    }
  }

  .form-group {
    flex-wrap: wrap;

    input {
      width: 100%;
    }

  }
}

.dark-opacity {
  opacity: 1;
}

.checkout-select.checkout-input:not(select) {
  height: 45px;
  border-radius: 4px;
  border: 1px solid #aaa;
  background-color: white;
}

.checkout-select {
  width: 100%;
  max-width: 100%;
}

.checkout-input {
  padding: 0 15px;
  font-size: 14px;

  &:focus {
    opacity: 1;
  }
}

.card-header,
.card-footer {
  border-top: 1px solid $card-border-color;
  padding: 1.5rem 2rem;
  border-bottom: none;
  background-color: transparent;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }

  &:last-child {
    text-align: center;
  }
}

.add-new-address-body {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 30px;
}

.form-control {
  border-radius: 2px;
  line-height: 1.25;
  font-size: 15px;
}

.form-control:focus {
  border-color: black !important;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
}

.new-adresses-select {
  width: 100%;

  select {
    width: 100%
  }
}

.new-adresses_address {
  width: 100%;

  input {
    width: 100%
  }
}

.order-details-info_addresses {
  font-size: 14px;
  color: #707070;
  font-weight: 400;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 22px 2.5rem;
  border-bottom: 1px solid #f0f0f0;

  .order-info-1 {
    width: 180px;
  }
}

.order-details-info {
  font-size: 14px;
  color: #303030;
  font-weight: 400;
  align-items: center;
  display: grid;
  grid-template-columns: 36% 16% 16% 16% 16%;
  padding: 22px 2.5rem;
  border-bottom: 1px solid $card-border-color;

  .order-info-2 {
      text-align: center;
  }

  .order-info-2:first-child {
    text-align: left;
  }

  .order-info-2:last-child {
    text-align: right;
  }

  @media screen and (max-width: 991px) {
    padding: 14px 24px;
  }



  .order-info-2 {
    padding: 0 10px;
  }
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 2rem;
}

.order-header__title {
  margin-bottom: 0;
  color: #121212;
  font-size: 20px;

  @include direction {
    #{$padding-inline-end}: 3.5rem;
    float: $inline-start;
  }
}

.order-header__actions {
  margin-top: -1px;

  @include direction {
    float: $inline-end;
  }
}

.back_list {
  display: flex;
  align-items: center;
  color: #0D47A1;

  &:hover {
    color: #001973;

    svg {
      path {
        fill: #001973;
      }
    }
  }

  svg {
    width: 15px;
    height: 18px;
    margin: 0 4px 1px 0;

    path {
      fill: #0D47A1;
    }
  }
}

.order-wrap-layer-title2 {
  border-bottom: 1px solid $card-border-color;
  border-top: 1px solid $card-border-color;
  background: #F6F6F6;
  padding: 17px 2.5rem;
  color: #2B2B2B;
  font-weight: 600;
  font-size: 18px;
  display: grid;
  grid-template-columns: 36% 16% 16% 16% 16%;

  span {
    text-align: center;
  }

  span:first-child {
    text-align: left;
  }

  span:last-child {
    text-align: right;
  }
}

.order-wrap-layer-title {
  border-bottom: 1px solid $card-border-color;
  border-top: 1px solid $card-border-color;
  background: #F6F6F6;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 17px 2.5rem;
  color: #2B2B2B;
  font-weight: 600;
  font-size: 18px;

  @media screen and (max-width: 991px) {
    padding: 16px 24px;
  }

  .order-title-line-1 {
    width: 180px;
    font-size: 16px;
  }

  .order-title-line-2 {
    width: 150px;
    font-size: 16px;
  }
}

.order-details-bottom-info {
  width: 300px;
  margin: 2rem 0 0 auto;
  background: #F6F6F6;
  padding: 1.625rem 2rem 1.375rem;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;

  > div {
    width: 100%;
  }
}

.reorder-btn-parent {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

}

.order-details-mobile {
  width: 100%;

  .card-mobile-header {
    .order-details-mobile-header {
      justify-content: space-between;

      .order_title {
        display: flex;
        align-items: center;
        gap: 10px;

        .order_title_text {
          font-weight: 600;
          font-size: 14px;
        }
      }


    }
  }

  .orders-inner-details-mobile {
    .orders-inner-details-header-mobile {
      display: flex;
      flex-direction: column;
      gap: 18px;
      padding: 15px 10px;

      .order-inner-detail-rows {
        display: flex;
        justify-content: space-between;

        .table-heading-descriptions {
          font-size: 14px;
        }
      }
    }
  }

  .orders-products-names-mobile {
    border-top: 1px solid #E8E8E8;
    border-bottom: 1px solid #E8E8E8;
    margin: 0 10px;

    .order-details-product {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 15px 0;

      .order-details-name-width {
        color: #161616;
      }

      .order-details-price {
        color: #626262;

        span:last-child {
          margin-left: 10px;
        }
      }
    }
  }

  .order-details-info-mobile {
    .order-details-info-table-mobile {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin: 15px 10px;
      width: auto !important;

      tr {
        th {
          font-size: 14px;
        }
      }
    }
  }
}

.reorder-btn {
  max-width: 300px;
  width: 100%;
  border: none;
  border-radius: 4px !important;
  height: 40px;

  @media screen and (max-width: 425px) {
    max-width: unset;
    width: unset;
  }
}

.order-details-mobile-header {
  width: 100%;
  height: 52px;
  background-color: #F7F7F7;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 17px 0 10px;
}

.orders-inner-details-mobile {
  & > div > .table-heading-descriptions {
    margin-right: 15px;
  }
}

.table-heading-descriptions {
  color: #1a2238;
  font-size: 16px;
  font-weight: 600;
}

.mobile-order-info {
  width: 150px;
  text-align: end;
}

.underline {
  border-bottom: 1px solid #0000EE;
  color: #0000EE;
}

.order__id {
  color: #0D47A1;
}

.orders-page-mobile {
  .order-history-card-mobile {
    min-height: auto;

    .order-card-mobile-top-position {
      tr {
        td {
          gap: 8px;
          font-size: 14px;
          align-items: center;

          @media screen and (max-width: 425px) {
            font-size: 14px;
          }

          th {
            font-size: 14px;
          }
        }
      }
    }

    table {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .table_column {
        display: flex;
        flex-direction: column;
        width: 50%;
      }

      tr {
        width: 100%;

        th {
          font-size: 14px;
        }

        td {
          font-size: 16px;

          @media screen and (max-width: 425px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.order-history-card-mobile {
  margin-bottom: 20px;

  th {
    font-size: 16px;
    color: #241f23;
  }

  tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    padding: 0 10px;
    text-align: left;

    th, td {
      width: 50%;
    }
  }

  td {
    padding-top: 0;
  }
}

.order-card-mobile-top-position {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #F6F6F6;
  flex-direction: row;

  thead {
    width: 100%;
  }

  & > tr {
    width: 50%;
  }
}

.order-card-mobile-title {
  width: 100%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  font-weight: bold;

  a {
    margin-left: 10px;
  }
}

.order-id-mobile-href {
  color: #0D47A1;
  font-weight: 400;
}

.order-card-mobile-options {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.card-table {
  font-size: 15px;

  table {
    width: 100%;
    min-width: 500px;

    @include direction {
      text-align: $inline-start;
    }
  }

  thead {
    th {
      border-bottom: $card-border-width solid $card-border-color;
      text-transform: uppercase;
      color: #2B2B2B;
      font-size: 13px;
      font-weight: $font-weight-semi-bold;
      padding: 10px;
    }
  }

  .last_column {
    text-align: end;
  }

  tbody,
  tfoot {
    th,
    td {
      padding: 12px 12px;
      text-align: center;
    }

    .dwn_tr {
      .dwn_column {
        padding: 12px 30px;

        .download__btn_container {
          gap: 5px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          flex-direction: row;

          .download__btn {
            cursor: pointer;
            text-align: left;
            background: none;

            b {
              color: black;
              white-space: nowrap;
            }

            a {
              gap: 5px;
              display: flex;
              align-items: center;
              flex-direction: row;

              .link_container {
                gap: 3px;
                display: flex;
                align-items: center;
                flex-direction: row;

                svg {
                  width: 18px;
                  height: 18px;

                  path:not(:first-child) {
                    stroke: #436cdf;
                  }
                }

                span {
                  color: #436cdf;
                  font-size: 14px;
                }

                &:hover {
                  svg {
                    path:not(:first-child) {
                      stroke: #2856da;
                    }
                  }

                  span {
                    color: #2856da;
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }

        .disabled__product_name {
          color: #707070 !important;

          .disabled__link_container {
            gap: 5px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            flex-direction: row;

            b {
              color: #707070;
              white-space: nowrap;
            }

            span {

            }
          }
        }
      }

      .dwn_column:nth-child(2) {
        text-align: left;
      }
    }
  }

  tfoot {
    th,
    td {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  tbody + tbody tr,
  tbody + tfoot tr,
  tbody tr + tr {
    th,
    td {
      border-top: 1px solid $card-border-color;
    }
  }

  tbody,
  tfoot {
    tr:hover > * {
      background-color: #f7f7f7;
    }
  }

  th, td {
    @include direction {
      &:first-child {
        #{$padding-inline-start}: 2rem;
      }
      &:last-child {
        #{$padding-inline-end}: 2rem;
      }
    }
  }

  .card-table__body--merge-rows {
    th,
    td {
      padding-top: 3px;
      padding-bottom: 3px;
    }

    tr:first-child {
      th,
      td {
        padding-top: 12px;
      }
    }

    tr:last-child {
      th,
      td {
        padding-bottom: 12px;
      }
    }

    tr + tr {
      th,
      td {
        border-top: none;
      }
    }
  }
}

.input-fields-parent {
  padding: 30px 20px;
}

.personal-info-card {
  width: 100%;
}

.input-paddings {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  /*only for account page*/
  width: 100%;
}

.account-input-title {
  font-size: 14px;
  font-weight: 100;
}

.input-design {
  height: 35px;

  &.button:hover {
    cursor: pointer;
  }
}

.form-select {
  border: 1px solid #aaa;
  border-radius: 5px;

  &:focus-visible {
    outline: none !important;
  }
}

.button-change-password {
  background: rgb(235, 233, 233) !important;
  border: 1px solid #aaa !important;
  height: 42px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  color: #3d464d;
  font-size: 16px;
  cursor: pointer;
}

.card_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.account-save-profile {
  display: flex;
  justify-content: flex-end;
}

.reset-modal {
  flex-direction: column;
  padding: 30px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-title {
  display: flex;
  justify-content: center;
  font-size: 32px;
  align-items: center;
  padding-bottom: 60px;
  border-bottom: 1px solid #e8e8e8;

  svg {
    margin-right: 20px;
  }
}

.modal-href {
  color: #1a66ff !important;
  font-size: 16px;
  text-decoration: revert;
}

.order-details-info-table {
  width: 100%;
  min-width: 20px;
  margin-left: auto;

  tr {
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }

  td {
    text-align: left;
  }
}

.invalid-fields {
  cursor: no-drop;
}

.passwordChange {
  max-width: 320px;
  margin: 60px;

  @media screen and (max-width: 600px) {
    margin: 30px;
  }

  @media screen and (max-width: 425px) {
    max-width: 90%;
  }

  h1 {
    color: #161616;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  button {
    padding: 10px 30px;
    min-width: 167px;
  }

  .we-recommend {
    margin-top: 20px;
  }

  .modal-newpasswords {
    p {
      margin-top: 20px;
    }
  }

  input {
    width: 100%;
  }

  .modalss {
    left: 0;
  }

  .modal-body {
    padding: 0 85px 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      margin-bottom: 5px;
      font-size: 14px;
    }

    h1 {
      font-size: 32px;
      text-align: center;
    }
  }

  span {
    p {
      font-size: 12px;
    }
  }

  .modal-body-forms {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding-top: 50px;
  }

}

.new-passwords {
  margin: 40px 0 0 0;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.error-message-change-password {
  font-size: 12px;
  font-weight: 800;
  color: red;
  text-align: start;
}

.we-recommend,
.socials_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 20px 0 20px;
  flex-direction: column;

  .links_icons {
    text-align: center;
    margin: 0 auto;
  }
}

.account-menu-login {
  border-radius: 10px;
  background: #fff;
  color: #3d464d;
  width: 100%;
  max-width: 500px;
  margin: 80px auto;
  border: 1px solid #e6e6e6;

  .socials_content {
    .social-links_line {
      justify-content: space-evenly;
    }

    .links_icons {
      margin: 0 10px;
    }

    .social_line {
      width: 80px;
    }
  }
}

.social-line-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
}

.social_line {
  width: 40px;
  height: 1px;
  background-color: #aaaaaa;
}

.social_line-span {
  margin: 0 10px;
  color: #787878;
  cursor: auto;
}

.social-links_line {
  display: flex;
  width: 100%;
  margin-bottom: 25px;
  justify-content: space-evenly;
}

.modalss {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 202;
  transition: 0.5s;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  left: 0;
}

.modalss.active {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.modal-content {
  background-color: white;
  z-index: 101;
  transform: scale(0.5);
  transition: 0.4s all;
  border: none;
  border-radius: $modal-border-radius;
  box-shadow: $modal-shadow;
  max-width: 1001px;
}

.modal-content.active {
  text-align: center;
  transform: scale(1);
}

.modal-string {
  font-size: 20px;
  margin: 50px 0;
}

@media screen and (min-width: 992px) {
  .card_mobile{
    display: none;
  }

  .account-nav__item--active a {
    color: $body-font-color;
    font-weight: $font-weight-medium;

    path {
      stroke: $main-theme-color-light;
    }
  }

  .account-nav {
    ul {
      padding-bottom: 18px;
      width: 100%;
    }
  }
}

.card_desktop {
  height: 100%;
}

@media screen and (max-width: 991px) {

  .downloads_column_mob {
    .download__btn_container {
      gap: 5px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      flex-direction: row;


      .download__btn {
        cursor: pointer;
        text-align: left;
        background: none;

        a {
          gap: 5px;
          display: flex;
          align-items: center;
          flex-direction: row;

          .link_container {
            gap: 3px;
            display: flex;
            align-items: center;
            flex-direction: row;

            svg {
              width: 18px;
              height: 18px;

              path:not(:first-child) {
                stroke: #436cdf;
              }
            }

            span {
              color: #436cdf;
              font-size: 14px;
            }

            &:hover {
              svg {
                path:not(:first-child) {
                  stroke: #2856da;
                }
              }

              span {
                color: #2856da;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    .disabled__product_name {
      color: #707070 !important;

      .disabled__link_container {
        gap: 5px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: row;

        b {
          color: #707070;
          white-space: nowrap;
        }

        span {

        }
      }
    }
  }

  .card_desktop,
  .card_discount{
    display: none;
  }

  .card_mobile {
    height: 100%;
    display: block;

    .orders-page-mobile {
      height: 100%;
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      align-items: center;
      justify-content: space-between;
    }
  }

  .account-links-body .account-nav__item {
    flex: 50%;
  }

  .account-nav__item {
    width: 100%;
  }

  .account-nav__item a {
    padding: 8px 16px;
  }

  .account-nav__item--active a {
    color: $body-font-color;
    font-weight: $font-weight-medium;
  }

  .account-nav ul .account-nav__item {
    &:last-child {
      border: none !important;
    }
  }

  .account-nav__title {
    text-align: center;
  }

  .account-layout-wrapper {
    .account-layout {
      width: calc(100% - 90px);

      @media(max-width: 991px) and (min-width: 768px) {
        width: 100%;
      }
    }
  }

  .account-nav ul {
    justify-content: center;
  }

  .account-nav {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    ul {
      display: flex;
      flex-wrap: nowrap;
      white-space: nowrap;
      flex-direction: column;
      width: 100%;
      border-bottom: $card-border-width solid $card-border-color;
    }
  }

  .account-layout-content-margin {
    height: 100%;
    margin: unset;

    .orders-page-mobile {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      flex-direction: column;

      .order-history-card-mobile {
        width: 100%;
        margin: 0;

        @media screen and (max-width: 700px) {
          width: 100%;
        }

        .order-card-mobile-top-position {
          .order-card-mobile-title {
            gap: 10px;
            justify-content: unset !important;
          }

          .downloads_column_mob {
            .download__btn_container {
              gap: 5px;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              flex-direction: row;

              .download__btn {
                cursor: pointer;
                text-align: left;
                background: none;

                b {
                  color: black;
                  white-space: nowrap;
                }

                a {
                  gap: 5px;
                  display: flex;
                  align-items: center;
                  flex-direction: row;

                  .link_container {
                    gap: 3px;
                    display: flex;
                    align-items: center;
                    flex-direction: row;

                    svg {
                      width: 18px;
                      height: 18px;

                      path:not(:first-child) {
                        stroke: #436cdf;
                      }
                    }

                    span {
                      color: #436cdf;
                      font-size: 14px;
                    }

                    &:hover {
                      svg {
                        path:not(:first-child) {
                          stroke: #2856da;
                        }
                      }

                      span {
                        color: #2856da;
                        text-decoration: underline;
                      }
                    }
                  }
                }
              }
            }
          }

          .disabled__product_name {
            color: #707070 !important;

            .disabled__link_container {
              gap: 5px;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              flex-direction: row;

              b {
                color: #707070;
                white-space: nowrap;
              }

              span {

              }
            }
          }

        }

        .table_column {
          gap: 15px;
          padding: 10px;

          tr {
            margin: 0;
            padding: 0;
            gap: 10px;
            justify-content: unset;
          }
        }
      }
    }
  }

  .input-fields-parent {
    padding: 10px 0;
  }

  .modal-title {
    text-align: center;
    padding: 0 20px 40px 20px !important;
    font-size: 20px !important;

    svg {
      width: 22%;
      margin-right: unset !important;
      margin-bottom: auto !important;
    }
  }

  .modal-string {
    font-size: 18px;
    margin: 30px 0;
    padding: 0 20px;
    text-align: center;
  }

  .modal-content-active-register {
    min-height: 0 !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 991px) {
  .card-title {
    font-size: 26px;
    margin-bottom: 1.5rem;
  }

  .card-body {
    padding: 1.5rem;
  }

  @include local-layout(3, 12px);

  .card-header,
  .card-footer {
    padding: 1.25rem 1.5rem;
  }

  .order-header {
    padding: 24px
  }

  .card-table {
    font-size: 14px;

    th, td {
      @include direction {
        &:first-child {
          #{$padding-inline-start}: 1.5rem;
        }
        &:last-child {
          #{$padding-inline-end}: 1.5rem;
        }
      }
    }
  }

}

@media screen and (max-width: 767px) {
  .card-title {
    font-size: 26px;
    margin-bottom: 1.5rem;
  }

  .card-body {
    padding: 1.375rem;
  }

  .account-menu__form-forgot {
    a {
      font-size: 12px;
      line-height: 16px;
      text-align: right;
      text-transform: capitalize;
      color: $body-font-color;
      margin-top: 8px;
      height: 20px;
      margin-left: auto;
      width: 100%;
    }
  }

  @include local-layout(2, 16px);

  .form-group {
    margin-bottom: 1.125rem;
  }

  .checkout-select,
  .checkout-select-billing {
    max-width: 470px;
    width: 100%;
  }

  .card-header,
  .card-footer {
    padding: 1rem 1.375rem;
  }

  .order-header {
    padding: 1.125rem 1.375rem 1rem;
  }

  .card-table {
    font-size: 14px;

    th, td {
      @include direction {
        &:first-child {
          #{$padding-inline-start}: 1.375rem;
        }
        &:last-child {
          #{$padding-inline-end}: 1.375rem;
        }
      }
    }
  }

  .personal-info-card {
    margin-right: 0;
    margin-left: 0;
  }

  .account-links-body .account-nav__item {
    flex: 0;
  }

  .account-layout-wrapper {
    .account-layout {
      width: 100%;
    }
  }

  .account-nav ul {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .input-fields-parent {
    padding: 0;
  }

  .account-menu-login {
    .socials_content {
      .links_icons {
        margin: 0 5px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  @include local-layout(1, 16px);

  .personal-info-card {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .account-save-profile {
    padding-top: 0;
    display: flex;
    justify-content: center;
    margin: 10px 0;
  }

  .order-details-info-table-mobile {
    width: 100%;
  }
}

@media screen and (max-width: 375px) {
  .personal-info-card {
    display: flex !important;
  }
}