@import "../../../scss/variables";
@import "../../../scss/functions";
@import "../../../scss/mixins/hacks";
@import "../../../scss/mixins/direction";

.top-bar {
  height: 40px;
  background: $topbar-bg;
  box-shadow: $topbar-shadow;
  font-size: 14px;
  color: $topbar-font-color;

  .top-bar-main {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .my_account {

      a {
        color: #707070;
      }
    }
    .nav-panel__item-custom {
      position: relative;

      .nav-panel__account-nav {
        position: absolute;
        z-index: 99999;
        width: 260px;
        //padding-top: 20px;
        top: 100%;
        right: 0;
        display: none;

        & > div {
          background-color: #fff;
          width: 100%;
        }
        ul {
          padding-bottom: 0;
        }
      }

      &:hover .nav-panel__account-nav,
      &:hover .nav-panel__account-nav:hover,
      .nav-panel__account-nav:hover {
        display: flex;
      }
      .account-nav__item {
        cursor: pointer;
        padding: 11.5px 10px;
        margin: 4px 10px;
        &:hover {
          background-color: #f7f7f7;
          text-decoration: underline;
        }
        a {
          color: $body-font-alt-color;
          font-weight: 300;
          font-size: 14px;
          color: #000;
          display: flex;
          align-items: center;
          gap: 5px;

          span {
            display: flex;
            width: 30px;
          }
        }
        &:last-child {
          border-top: 1px solid #ddd;
        }

        .logout {
          color: red;
        }
      }
      .account-nav__item--active {
        background-color: #f7f7f7
      }
    }

    .account_indicators {
      display: flex;
      gap: 10px;
      align-items: center;
      flex-direction: row;

      a {
        color: #707070;
        cursor: pointer;
        font-size: 14px;
      }

      .account-dropdown__opened {
        top: 40px;
        z-index: 300;
        position: absolute;
        margin-left: -237px;

        .account-menu {
          box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
          background: #fff;
          color: #3d464d;
          width: 280px;
          overflow-y: auto;
          overscroll-behavior-y: contain;
          -webkit-overflow-scrolling: touch;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;

          .account-menu__form {
            padding: 0 30px 32px;

            .account-menu__form-title {
              text-align: center;
              padding: 20px 0 6px;
              font-weight: 700;
              font-size: 30px;
            }

            .account-menu_loginAndPass {
              display: flex;
              flex-direction: column;
              margin-top: 20px;

              input {
                padding: 12px;
              }

              a {
                font-size: 12px;
                line-height: 16px;
                text-transform: capitalize;
                color: $body-font-color;
                margin-top: 8px;
                text-decoration: underline;
              }
            }

            .account-menu__form-button {
              margin-top: 32px;
              text-align: center;
              margin-bottom: 1rem;

              .rounded-pills {
                color: white;
                width: 100%;
                border-radius: 30px;
                text-align: center;
                padding: 6px 28px;
              }
            }

            .account-menu__form-link {
              font-size: 14px;
              text-align: center;
              margin-left: unset;
              color: $body-font-color;

              .new-customer {
                font-size: 14px;
                text-align: center;
                color: #2f2f2f;
                margin-right: 10px;
              }

              a {
                transition: color 0.1s;
                font-size: 12px;
                line-height: 16px;
                text-transform: capitalize;
                color: $body-font-color;
                margin-top: 8px;
                text-decoration-line: underline;
              }

              a:hover {
                background: none;
                color: #000C66;
                text-decoration: underline;
              }

              a:active {
                background: none;
                color: #3d464d;
              }
            }
          }
        }
      }

      .account-dropdown__closed {
        display: none;
      }
    }

    .topbar_buttons {
      display: flex;
      gap: 20px;
      height: 100%;

      .language-button {
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 10px;
        position: relative;

        span {
          font-size: 14px;
        }

        .menu-none {
          display: none;
        }

        svg {
          transition: .5s;

          &.rotT_fms {
            transform: rotate(180deg);
          }
        }

        .menu-block {
          position: absolute;
          top: 100%;
          left: 0;
          z-index: 201;
          transition-delay: 0s, 0s, 0s;
          opacity: 1;
          visibility: visible;
          transform: rotateX(0deg);
          transition: transform .2s;

          .menu {
            color: #353535;
            background: #fff;
            list-style: none;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: .02em;
            font-weight: 500;
            padding: 6px 0;
            width: 150px;
            box-shadow: $menu-topbar-shadow;

            .menu_item {
              background: transparent;
              border: none;
              width: 100%;
              font-weight: inherit;
              display: flex;
              align-items: center;
              gap: 20px;
              padding: 8px;
              color: #121212;
              transition: .5s;

              &:hover {
                background: $menu-item-hover-bg;
              }

              .menu__icon {
                display: flex;
              }
            }
          }
        }
      }
    }
  }
}



@media screen and (max-width: 991px) {
  .top-bar {
    display: none;
  }

  .language-button {
    border-bottom: 1px solid #ebebeb;
    display: grid;
    grid-template-columns: 20px auto 55px;
    gap: 15px;
    align-items: center;
    font-size: 16px;
    color: #121212;
    padding: 18px 5px;

    .mobile_currency__symbol {
      border: 1px solid #f1f1f1;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 5px;
      flex-shrink: 0;
      grid-column: 1/2;
      grid-row: 1/2;
      max-width: 20px;
    }

    .mobile-links__item-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }

    svg {
      margin: 0 auto;
      transition: .3s;
    }

    .rotT_fms {
      transform: rotate(180deg);
      transition: .3s;
    }

    .menu-block {
      display: block;
      grid-column: 1/4;
      animation: showHide .5s;
      padding-left: 24px;
      border-top: 1px solid #ebebeb;
      margin-bottom: -18px;

      .menu {
        .menu_item {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          height: 30px;
        }
      }
    }

    @keyframes showHide {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    .menu-none {
      display: none;
    }
  }
}